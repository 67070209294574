import React, { useState } from "react";
import { addFaq, addLanguage } from "../../networkRequests/config";
import { loading, toastError, toastSuccess } from "../../utlis/common";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

export default function AddFaq({ status, onClose, refreshList }) {
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [loader, setLoader] = useState(false);
  const _addLanguage = async () => {
    if (!title) {
      return toastError("Please enter title");
    }
    if (!description) {
      return toastError("Please enter description");
    }
    setLoader(true);
    try {
      const res = await addFaq({ title, description });
      console.log(res);
      toastSuccess("FAQ Successfully added");
      refreshList();
      onClose();
      setTitle();
      setDescription();
      setLoader(false);
    } catch (error) {
      setLoader(false);
      toastError(error.data.message);
      console.log(error);
    }
  };

  return (
    <Modal
      open={status}
      onClose={() => {
        onClose();
      }}
    >
      {loader && loading}

      {/* <div className="card  px-0"> */}
      <div className="card-header">
        <h6 className="my-2">Add FAQ</h6>
      </div>
      <form id="submit">
        <div className="card-body">
          <div className="form-group">
            <label>Title</label>
            <input
              value={title}
              required
              className="form-control"
              placeholder="Title"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </div>
          <div className="form-group">
            <label>Description</label>
            <input
              value={description}
              required
              className="form-control"
              placeholder="Description"
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="card-footer ">
          <button
            className="btn btn mr-3"
            style={{
              background: "rgb(244, 108, 3) none repeat scroll 0% 0%",
              color: "white",
            }}
            onClick={(e) => {
              e.preventDefault();
              _addLanguage();
            }}
          >
            Submit
          </button>
          <button
            type="reset"
            onClick={() => {
              setTitle();
              setDescription();
            }}
            className="btn btn-secondary"
          >
            Clear
          </button>
        </div>
      </form>
      {/* </div> */}
    </Modal>
  );
}
