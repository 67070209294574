import React from "react";
import FAndQs from "./pages/config/FAndQs";

const ChangePassword = React.lazy(() => import("./pages/ChangePassword"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const workProviderList = React.lazy(() =>
  import("./pages/workProvider/WorkProviderList")
);
const workSeekerList = React.lazy(() =>
  import("./pages/workSeeker/WorkSeeker")
);
const assignmentList = React.lazy(() =>
  import("./pages/assignment/AssignmentList")
);
const assignmentDetail = React.lazy(() =>
  import("./pages/assignment/Assignmentdetail")
);
const addSkill = React.lazy(() => import("./pages/config/AddSkill"));
const skills = React.lazy(() => import("./pages/config/Skills"));
const languages = React.lazy(() => import("./pages/config/Languages"));
const addLanguage = React.lazy(() => import("./pages/config/AddLanguage"));
const workProviderDetail = React.lazy(() =>
  import("./pages/workProvider/workProviderDetail")
);
const workSeekerDetail = React.lazy(() =>
  import("./pages/workSeeker/workSeekerDetail")
);

const fAndQ = React.lazy(() => import("./pages/config/FAndQs"));

const routes = [
  { path: "/dashboard", exact: true, name: "Dashboard", component: Dashboard },

  {
    path: "/changePassword",
    exact: true,
    name: "Change Password",
    component: ChangePassword,
  },
  {
    path: "/workproviders",
    exact: true,
    name: "Work Providers",
    component: workProviderList,
  },
  {
    path: "/workseekers",
    exact: true,
    name: "Work Seekers",
    component: workSeekerList,
  },

  {
    path: "/assignments",
    exact: true,
    name: "Assignment List",
    component: assignmentList,
  },
  {
    path: "/assignments/:assignmentsId",
    exact: true,
    name: "Assignment List",
    component: assignmentDetail,
  },
  {
    path: "/skills",
    exact: true,
    name: "Assignment List",
    component: skills,
  },
  {
    path: "/languages",
    exact: true,
    name: "Language List",
    component: languages,
  },
  {
    path: "/addSkill",
    exact: true,
    name: "Skill Add",
    component: addSkill,
  },
  {
    path: "/addLanguage",
    exact: true,
    name: "Add Language",
    component: addLanguage,
  },

  {
    path: "/workProvider/:userId/:workProviderId",
    exact: true,
    name: "WorkProvider Detail",
    component: workProviderDetail,
  },
  {
    path: "/workSeeker/:userId/:workSeekerId",
    exact: true,
    name: "WorkProvider Detail",
    component: workSeekerDetail,
  },
  {
    path: "/fAndQ",
    exact: true,
    name: "Frequently Asked Questions",
    component: fAndQ,
  },
];
export default routes;
