import qs from "qs";

import {
  makePostRequest,
  makeGetRequest,
  makePutRequest,
  makeDeleteRequest,
} from "./AxiosBase";

export const skillList = async () => {
  try {
    const params = {};
    const config = { skipAuth: false };
    let res = await makeGetRequest("/user/skill", params, config, {
      crossDomain: true,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const addSkill = async (name) => {
  try {
    const params = { name };
    const config = { skipAuth: false };
    let res = await makePostRequest(
      "/user/skill",
      qs.stringify(params),
      config,
      {
        crossDomain: true,
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const languageList = async () => {
  try {
    const params = {};
    const config = { skipAuth: false };
    let res = await makeGetRequest("/user/language", params, config, {
      crossDomain: true,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const addLanguage = async (name) => {
  try {
    const params = { name };
    const config = { skipAuth: false };
    let res = await makePostRequest(
      "/user/language",
      qs.stringify(params),
      config,
      {
        crossDomain: true,
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteSkill = async (skillId) => {
  try {
    const params = { skillId };
    const config = { skipAuth: false };
    let res = await makeDeleteRequest("/user/skill", params, config, {
      crossDomain: true,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteLanguage = async (languageId) => {
  try {
    const params = { languageId };
    const config = { skipAuth: false };
    let res = await makeDeleteRequest("/user/language", params, config, {
      crossDomain: true,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getFaqs = async (data) => {
  try {
    const config = { skipAuth: false };
    let res = await makeGetRequest("/admin/auth/fAndQ/list", data, config, {
      crossDomain: true,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const addFaq = async (data) => {
  try {
    const config = { skipAuth: false };
    let res = await makePostRequest(
      "/admin/auth/fAndQ",
      qs.stringify(data),
      config,
      {
        crossDomain: true,
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteFaq = async (data) => {
  try {
    const config = { skipAuth: false };

    let res = await makeDeleteRequest("/admin/auth/fAndQ", data, config, {
      crossDomain: true,
    });

    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getFaq = async (data) => {
  try {
    const config = { skipAuth: false };
    let res = await makeGetRequest("/admin/auth/fAndQ", data, config, {
      crossDomain: true,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const editFaq = async (data) => {
  try {
    const config = { skipAuth: false };
    let res = await makePutRequest(
      "/admin/auth/fAndQ",
      qs.stringify(data),
      config,
      {
        crossDomain: true,
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const changeOrder = async (data) => {
  try {
    data = {
      items: [
        ...data.map((item) => {
          return { order: item.order, _id: item._id };
        }),
      ],
    };
    const config = { skipAuth: false };
    let res = await makePutRequest(
      "/admin/auth/fAndQ/changeOrder",
      qs.stringify(data),
      config,
      { crossDomain: true }
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};
