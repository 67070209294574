import Header from "./Header";
import Content from "./Content";
import NavBar from "./NavBar";
import Footer from "./Footer";

import React from "react";

export default function Layout(props) {
  return (
    <div>
      <Header />
      <Content />
      <NavBar />
      <Footer />
    </div>
  );
}
