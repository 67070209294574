import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { changeOrder, deleteFaq, getFaqs } from "../../networkRequests/config";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import { loading, toastError, toastSuccess } from "../../utlis/common";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import AddFaq from "./AddFaq";
import EditFaq from "./EditFaq";

export default function FAndQs() {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const [alertStatus, setAlertStatus] = useState(false);
  const [selectedFaq, setSelectedFaq] = useState("");
  const [addFaq, setAddFaq] = useState(false);
  const [editFaq, setEditFaq] = useState();
  const [orderChanged, setOrderChanged] = useState(false);

  const _languageList = async () => {
    try {
      setLoader(true);
      const res = await getFaqs();
      setFaqs(res.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const _deletFaq = async () => {
    setLoader(true);
    try {
      await deleteFaq({ id: selectedFaq });
      toastSuccess("FAQ Successfully deleted");
      await _languageList();
      setLoader(false);
    } catch (error) {
      setLoader(false);
      toastError(error.data.message);
    }
  };

  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    var updatedList = [...faqs];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    updatedList.map((data, index) => (data.order = index));
    setOrderChanged(true);
    setFaqs(updatedList);
  };

  const _changeOrder = async () => {
    try {
      setLoader(true);
      const res = await changeOrder(faqs);
      console.log(res);
      setLoader(false);
      setOrderChanged(false);
    } catch (error) {
      setLoader(false);
      toastError(error.data.message);
    }
  };

  useEffect(() => {
    _languageList();
  }, []);

  return (
    <div>
      {loader && loading}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">FAQ</h1>
            </div>

            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <NavLink to="/">Home</NavLink>
                </li>
                <li className="breadcrumb-item active">FAQ</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div
                  className="card-header"
                  style={{ background: "#tranparent" }}
                >
                  <h3 className="card-title"></h3>

                  <div className="card-tools">
                    <div className="row">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <button
                            type="button"
                            className="btn btn-success mr-3 ml-4 "
                            onClick={() => {
                              setAddFaq(true);
                              // history.push("/addFaq");
                            }}
                          >
                            Add FAQ
                          </button>
                          {orderChanged && (
                            <button
                              type="button"
                              className="btn btn-success mr-3  "
                              onClick={() => {
                                _changeOrder();
                                // history.push("/addFaq");
                              }}
                            >
                              Save Order
                            </button>
                          )}

                          <div
                          // className="input-group border bg-white input-group-sm"
                          //style={{ borderRadius: 3 }}
                          >
                            {/* <button
                                type="button"
                                style={{
                                  width: "17vh",
                                  background:
                                    "rgb(244, 108, 3) none repeat scroll 0% 0%",
                                  color: "white",
                                }}
                                className="btn btn mr-3 ml-4 "
                                onClick={() => {
                                  history.push("/addDriver");
                                }}
                              >
                                Invite Driver
                              </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /.card-header */}
                {!faqs.length ? (
                  <div style={{ textAlign: "center" }}>
                    <h1>No Data</h1>
                  </div>
                ) : (
                  <DragDropContext onDragEnd={handleDrop}>
                    <div className="card-body table-responsive p-0">
                      <table className="table table-hover text-nowrap">
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <Droppable droppableId="droppable-1">
                          {(provider) => (
                            <tbody
                              ref={provider.innerRef}
                              {...provider.droppableProps}
                            >
                              {faqs.map((m, index) => (
                                <Draggable
                                  key={m._id}
                                  draggableId={m._id}
                                  index={index}
                                >
                                  {(provider) => (
                                    <tr
                                      {...provider.draggableProps}
                                      ref={provider.innerRef}
                                    >
                                      <td {...provider.dragHandleProps}>
                                        {m?.title}
                                      </td>
                                      <td {...provider.dragHandleProps}>
                                        {m?.description}
                                      </td>
                                      <td {...provider.dragHandleProps}>
                                        <i
                                          title="Edit"
                                          className="nav-icon fas "
                                          type="button"
                                          onClick={() => {
                                            setEditFaq(m._id);

                                            //setAlertStatus(true);
                                          }}
                                        >
                                          <AiOutlineEdit />
                                        </i>
                                        &nbsp;&nbsp;
                                        <i
                                          title="Delete"
                                          className="nav-icon fas "
                                          type="button"
                                          onClick={() => {
                                            setSelectedFaq(m._id);
                                            setAlertStatus(true);
                                          }}
                                        >
                                          <AiOutlineDelete />
                                        </i>
                                      </td>
                                    </tr>
                                  )}
                                </Draggable>
                              ))}
                            </tbody>
                          )}
                        </Droppable>
                      </table>
                    </div>
                  </DragDropContext>
                )}
              </div>
            </div>
            <SweetAlert
              show={alertStatus}
              warning
              showCancel
              confirmBtnText="Yes, delete"
              confirmBtnBsStyle="danger"
              title="Are you sure?"
              onConfirm={async () => {
                _deletFaq();
                setAlertStatus(false);
              }}
              onCancel={() => {
                setAlertStatus(false);
              }}
              //focusCancelBtn
            />
          </div>
        </div>
      </section>
      <AddFaq
        status={addFaq}
        onClose={() => setAddFaq(false)}
        refreshList={() => {
          _languageList();
        }}
      />
      <EditFaq
        status={editFaq}
        onClose={() => setEditFaq()}
        refreshList={() => {
          _languageList();
        }}
        faqId={editFaq}
      />
    </div>
  );
}
