import React from "react";
import { NavLink } from "react-router-dom";
import { AiFillDashboard, AiFillReconciliation } from "react-icons/ai";
import { FaUserTie, FaUser } from "react-icons/fa";
import { MdOutlineAssignmentTurnedIn } from "react-icons/md";
import { FaLanguage } from "react-icons/fa";
import { MdOutlineQuestionAnswer } from "react-icons/md";

export default function NavBar() {
  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <NavLink
          to="/"
          className="brand-link removeline"
          style={{ textDecoration: "none" }}
        >
          <img
            src="/dist/img/AdminLTELogo.png"
            alt="AdminLTE Logo"
            className="brand-image img-circle elevation-3"
            style={{ opacity: ".8" }}
          />
          <span className="font-weight-light removeline hide-when-collapse">
            Workies Admin
          </span>
        </NavLink>

        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item">
                <NavLink to="/dashboard" className="nav-link">
                  <i className="nav-icon far ">
                    <AiFillDashboard />
                  </i>
                  <p>Dashboard</p>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink to="/workproviders" className="nav-link">
                  <i className="nav-icon far ">
                    <FaUser />
                  </i>
                  <p>Work Provider</p>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink to="/workseekers" className="nav-link">
                  <i className="nav-icon far ">
                    <FaUserTie />
                  </i>
                  <p>Work Seeker</p>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink to="/assignments" className="nav-link">
                  <i className="nav-icon far ">
                    <MdOutlineAssignmentTurnedIn />
                  </i>
                  <p>Workies</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/skills" className="nav-link">
                  <i className="nav-icon far ">
                    <AiFillReconciliation />
                  </i>
                  <p>Skills</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/languages" className="nav-link">
                  <i className="nav-icon far ">
                    <FaLanguage />
                  </i>
                  <p>Languages</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/fAndQ" className="nav-link">
                  <i className="nav-icon far ">
                    <MdOutlineQuestionAnswer />
                  </i>
                  <p>FAQ</p>
                </NavLink>
              </li>
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
      {/* Content Wrapper. Contains page content */}
    </div>
  );
}
