//export const baseUrl = "http://18.134.224.198:5000";
//export const baseUrl = "http://localhost:5000";
export const baseUrl = "https://api.workies.com";

export const awsBaseUrl = "https://workies-bucket.s3.eu-west-2.amazonaws.com/";

export const userPerPage = 8;

export const ASSIGNMENT_STATUS = {
  DRAFT: "draft",
  PROPOSED: "proposed",
  IN_PROCESS: "inProcess",
  COMPLETED: "completed",
  OFFER_RECEIVED: "offerReceived",
};
